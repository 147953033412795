<div class="container my-5">

  <div class="row mb-3">
    <div class="col-12">
      <a class="mx-2" style="float: right; font-size: 1.5rem;" href="https://twitter.com/SlowestTimelord"><fa-icon [icon]="faTwitter"></fa-icon></a>
      <h3>Monitoring open source development in the Chia ecosystem.</h3>
      <small class="mb-5 d-block">Last updated: {{misc.last_updated | date:'medium'}}</small>
      <p>
        Public GitHub repos with the following topics are tracked:
        <span class="badge rounded-pill bg-primary mx-1">chia</span>
        <span class="badge rounded-pill bg-primary mx-1">chialisp</span>
        <span class="badge rounded-pill bg-primary mx-1">chia-blockchain</span>
        <span class="badge rounded-pill bg-primary mx-1">chia-network</span>
      </p>
      <p>
        Don't see a repo here? Make sure it is public and add one of the topics above!
      </p>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-6 col-md-3">
      <div class="card my-2 card-sm">
        <ng-container *ngIf="!repos.length">
          <div class="spinner-grow text-secondary align-self-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="repos.length">
          <div class="card-body">
            <h5 class="pt-1">repos</h5>
            <h5 class="mb-0"><span class="me-3"><fa-icon [icon]="faRepo"></fa-icon></span>{{repos.length}}</h5>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col-6 col-md-3">
      <div class="card my-2 card-sm">
        <ng-container *ngIf="!contributors.length">
          <div class="spinner-grow text-secondary align-self-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="contributors.length">
          <div class="card-body">
            <h5 class="pt-1">contributors</h5>
            <h5 class="mb-0"><span class="me-3"><fa-icon [icon]="faUser" class="mr-2"></fa-icon></span>{{contributors.length}}</h5>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col-6 col-md-6">
      <div class="card my-2 card-sm">
        <ng-container *ngIf="!repos.length">
          <div class="spinner-grow text-secondary align-self-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="repos.length">
          <div class="card-body">
            <h5 class="pt-1">latest repo</h5>
            <h5 class="mb-0">
              <span class="me-3"><fa-icon [icon]="faHistory"></fa-icon></span>
              <a [href]="'https://github.com/' + repos[repos.length-1].full_name">{{repos[repos.length-1].full_name}}</a>
            </h5>
          </div>
        </ng-container>
      </div>
    </div>

  </div>

  <div class="row mb-3">
    <div class="col-12 col-md-6">
      <div class="card my-2">
        <ng-container *ngIf="!reposData.length">
          <div class="spinner-grow text-secondary align-self-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="reposData.length">
          <div class="card-body">
            <div class="card-title">
              <h5>repos created by year</h5>
            </div>
            <div style="flex:auto; min-width: 0;">
              <div class="chart" style="width: 100%; height: 417px">
                <ngx-charts-bar-vertical [xAxis]="true" [yAxis]="true" [results]="reposData" scheme="solar" [schemeType]="ScaleType.Ordinal" style="fill: rgb(214, 214, 214);">
                </ngx-charts-bar-vertical>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="card my-2">
        <ng-container *ngIf="!popularRepos.length">
          <div class="spinner-grow text-secondary align-self-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="popularRepos.length">
          <div class="card-body">
            <div class="card-title">
              <h5>most popular repos</h5>
            </div>
            <ul class="list-group">
              <li *ngFor="let repo of popularReposPage" class="list-group-item">
                <a [href]="'https://github.com/' + repo.full_name" target="_blank">
                  {{repo.full_name}}
                </a>
                <span class="badge rounded-pill bg-warning text-dark mx-2"><fa-icon [icon]="faStar"></fa-icon> {{repo.stargazers_count}}</span>
              </li>
            </ul>
            <app-pagination [pageSize]="9" [items]="popularRepos" (changePage)="popularReposPage = $event.items"></app-pagination>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <div class="card my-2">
        <ng-container *ngIf="!commits[0].series.length">
          <div class="spinner-grow text-secondary align-self-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="commits[0].series.length">
          <div class="card-body">
            <div class="card-title">
              <h5>weekly activity</h5>
            </div>
            <div style="flex:auto; min-width: 0;">
              <div class="chart" style="width: 100%; height: 417px">
                <ngx-charts-area-chart [xAxis]="true" [yAxis]="true" [results]="commits" scheme="natural" [schemeType]="ScaleType.Ordinal" style="fill: rgb(214, 214, 214);">
                </ngx-charts-area-chart>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <div class="card my-2">
      <ng-container *ngIf="!contributors.length">
        <div class="spinner-grow text-secondary align-self-center" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </ng-container>
      <ng-container *ngIf="contributors.length">
        <div class="card-body">
          <div class="card-title">
            <h5>contributions by user <fa-icon class="mx-1" style="cursor: help;" [icon]="faInfo" title="Commits to main/master branches"></fa-icon></h5>
          </div>
          <div class="table-responsive">
          <table class="table table-hover" style="overflow: hidden;">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">contributor</th>
                <th scope="col">total commits <span role="button" style="cursor: pointer;" [ngStyle]="{color: filterBy == 'total' ? 'lightsalmon' : ''}" (click)="sortContributors('total')"><fa-icon [icon]="faDown"></fa-icon></span></th>
                <th scope="col">last month <span role="button" style="cursor: pointer;" [ngStyle]="{color: filterBy == 'month' ? 'lightsalmon' : ''}" (click)="sortContributors('month')"><fa-icon [icon]="faDown"></fa-icon></span></th>
                <th scope="col"># of repos</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contrib of contributorsPage; let i = index;">
                <td scope="row" class="pt-3">{{(i+1) + (40 * (contributorsPageIndex-1))}}</td>
                <td>
                  <img [src]="contrib.avatar_url" width="36" height="36" class="mx-3" style="border-radius: 6px;">
                  <a [href]="'https://github.com/' + contrib.login" class="me-4">{{contrib.login}}</a>
                  <fa-icon title="Contributed to the chia-blockchain repo" *ngIf="contributedToNode(contrib.repos)" class="mx-2" [icon]="faMedal" style="color: skyblue; cursor: help; vertical-align: text-top;"></fa-icon>
                  <fa-icon title="Contributed to 10 repos or more" *ngIf="contrib.repos.length >= 10" class="mx-2" [icon]="faMeteor" style="color: plum; cursor: help; vertical-align: text-top;"></fa-icon>
                  <fa-icon title="Contributed to a popular repo" *ngIf="hasPopularRepo(contrib.repos)" class="mx-2" [icon]="faStarOL" style="color: lightsalmon; cursor: help; vertical-align: text-top;"></fa-icon>
                </td>
                <td class="pt-3">{{contrib.contributions | number}}</td>
                <td class="pt-3"><span [ngStyle]="{opacity: contrib.last_month ? 1 : 0.25}">{{contrib.last_month | number}}</span></td>
                <td class="pt-3" >
                  <div class="popover_wrapper" style="cursor: pointer;">
                    {{contrib.repos_count}}
                    <span class="p-2 mx-1 popover_title"><fa-icon [icon]="faRepo"></fa-icon></span>
                    <div class="popover_content">
                      <a *ngFor="let repo of contrib.repos | slice:0:3; let last = last" class="d-block" [ngClass]="last ? '' : 'mb-2'" [href]="'https://github.com/' + repo">{{repo.split('/')[1]}}</a>
                      <span class="d-block mt-2" *ngIf="contrib.repos.length > 3">+{{contrib.repos.length-3}} more</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination [pageSize]="40" [items]="contributors" (changePage)="contributorsPage = $event.items; contributorsPageIndex = $event.page"></app-pagination>
        </div>
        </div>
      </ng-container>
    </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card my-2 card-sm pt-3">
        <div class="card-body">
          Maintained by <a href="https://slowesttimelord.com" target="_blank">SlowestTimelord</a> | Forked and modified from <a href="https://github.com/obsidiaHQ/nano-casa" target="_blank">nano.casa</a> by <a href="https://twitter.com/GeorgeMamar" target="_blank">George Mamar</a>.
        </div>
      </div>
    </div>
  </div>

</div>
